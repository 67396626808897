package com.example.blogmultiplatform.sections

import androidx.compose.runtime.Composable
import com.example.shared.JsTheme
import com.example.blogmultiplatform.util.Constants.FONT_FAMILY
import com.example.blogmultiplatform.util.isAdmin
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.shapes.Path
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.Col
import kotlin.js.Date

@Composable
fun FooterSection(
    breakpoint: Breakpoint
) {

    val pxPadding = if (breakpoint < Breakpoint.LG) 24.px else 40.px

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(topBottom = pxPadding)
            .borderRadius(
                topLeft = pxPadding * 4,
                topRight = pxPadding * 4
            )
            .backgroundColor(JsTheme.Secondary.rgb),
        contentAlignment = Alignment.Center,
    ) {
        if (breakpoint < Breakpoint.LG) {
            SmallScreenFooter()
        } else {
            WideScreenFooter()
        }
    }
}

@Composable
private fun SmallScreenFooter() {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        FooterText(
            "Copyright © ${Date().getFullYear()}"
//            + "  •  "
        )
        if (isAdmin()) {
            FooterLink(path = "/admin", text = "Go to Admin Page")
//                FooterText("  •  ")
        }
        Link(
            path = rememberPageContext().route.path,
            modifier = Modifier
                .fontFamily(FONT_FAMILY)
                .fontSize(14.px)
                .color(JsTheme.Primary.rgb)
                .onClick {
                    window.location.href = "https://samet-byte.github.io/go/"
                }
            ,
            text = "wanna know more about Sam?"
        )
        FooterText("Mobile version of the site")
        FooterText("is under development.")
        FooterText("Some features may not work")
        FooterText("as expected in mobile.")
    }
}

@Composable
private fun WideScreenFooter() {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Row{
            FooterText("Copyright © ${Date().getFullYear()}")
            FooterText("  •  ")

            if (isAdmin()) {
                FooterLink(path = "/admin", text = "Go to Admin Page")
                FooterText("  •  ")
            }
            Link(
                path = rememberPageContext().route.path,
                modifier = Modifier
                    .fontFamily(FONT_FAMILY)
                    .fontSize(14.px)
                    .color(JsTheme.Primary.rgb)
                    .onClick {
                        window.location.href = "https://samet-byte.github.io/go/"
                    }
                ,
                text = "wanna know more about Sam?"
            )
        }

        FooterText("Mobile version of the site is under development.")
        FooterText("Some features may not work as expected in mobile.")
    }
}

@Composable
private fun FooterLink(path: String, text: String) {
    Link(
        path = path,
        modifier = Modifier
            .fontFamily(FONT_FAMILY)
            .fontSize(14.px)
            .color(JsTheme.Primary.rgb)

        ,
        text = text
    )
}

@Composable
private fun FooterText(text: String = "") {
    SpanText(
        modifier = Modifier
            .fontFamily(FONT_FAMILY)
            .fontSize(14.px)
            .color(Colors.White),
        text = text
    )
}